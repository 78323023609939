@import 'quark/colors';

/* URL du logo à modifier */

$logo-url : '../../assets/favicon/favicon-96x96.png';

// _________________________________________________

/* Variables diverses à modifier en fonction du site */

// Background

$background-color: #fff;

// Formulaire

// Form box
$border-style-formulaire: 1px solid $primary-color; // entrer les paramètres (ex : 1px solid $base-color)
$border-radius-formulaire: 0; // changer la valeur (ex : 2px)
$box-shadow-formulaire: none; // entrer les paramètres (ex : 0px 0px 8px -2px #00A886)

// Input
$border-style-input: 1px solid $primary-color;
$border-radius-input: 0;
$box-shadow-input: none;

$border-style-input-focus: 1px solid lighten($primary-color, 10%);
$border-radius-input-focus: 0;
$box-shadow-input-focus: none;


// Bouton de connexion
$border-style-bouton : 0;
$button-color : $secondary-color;
$button-text-color : $white;

//Hover
$border-style-bouton-hover : 0;
$button-color-hover : darken($secondary-color, 5%);
$button-text-color-hover : $secondary-color;


// Liens
$link-color: $primary-color;
$link-color-hover: $secondary-color;

// _________________________________________________


/* Design login */

html {
    background: none;
}

body {
    text-align: center;
}

// scss-lint:disable all
#login {
    padding-top: 20px;
}
// scss-lint:enable all

.login {
    position: relative;
    background: $background-color;

    h1 a {
        background: url($logo-url) no-repeat center center / contain !important;
        width: 100%;

        &:focus {
            box-shadow: none;
        }
    }

    a {
        padding-top: 25px;
        padding-bottom: 25px;
        color: $link-color !important;
        font-size: 14px;
        font-weight: 500;

        &:hover {
            color: $link-color-hover !important;
        }
    }

    // FORMULAIRE

    form {
        border: $border-style-formulaire;
        border-radius: $border-radius-formulaire;
        box-shadow: $box-shadow-formulaire;

        input,
        select,
        textarea {
            border: $border-style-input;
            border-radius: $border-radius-input;
            box-shadow: $box-shadow-input;

            &:focus {
                border: $border-style-input-focus;
                border-radius: $border-style-input-focus;
                box-shadow: $box-shadow-input-focus;
            }
        }
    }

    // BOUTON DE CONNEXION

    .button.button-large {
        padding: 0 0 40px;
        width: 100%;
        height: 30px;
        line-height: 40px;
    }

    .button-primary {
        transition: ease .5s;
        margin-top: 50px;
        border: $border-style-bouton;
        box-shadow: none;
        background: $button-color;
        padding: 15px;
        text-transform: uppercase;
        text-decoration: none;
        text-shadow: none;
        letter-spacing: 2px;
        color: $button-text-color;
        font-weight: 500;

        &:hover,
        &:active,
        &:focus {
            transition: ease .5s;
            border: $border-style-bouton-hover;
            box-shadow: none;
            background-color: $button-color-hover;
            text-decoration: none;
            color: $button-text-color-hover;
        }
    }

    .message {
        border-left: 4px solid $secondary-color;
    }
}
