@charset "UTF-8";
/* URL du logo à modifier */
/* Variables diverses à modifier en fonction du site */
/* Design login */
html {
  background: none; }

body {
  text-align: center; }

#login {
  padding-top: 20px; }

.login {
  position: relative;
  background: #fff; }
  .login h1 a {
    background: url("../../assets/favicon/favicon-96x96.png") no-repeat center center/contain !important;
    width: 100%; }
    .login h1 a:focus {
      box-shadow: none; }
  .login a {
    padding-top: 25px;
    padding-bottom: 25px;
    color: #0197f6 !important;
    font-size: 14px;
    font-weight: 500; }
    .login a:hover {
      color: #ff3632 !important; }
  .login form {
    border: 1px solid #0197f6;
    border-radius: 0;
    box-shadow: none; }
    .login form input,
    .login form select,
    .login form textarea {
      border: 1px solid #0197f6;
      border-radius: 0;
      box-shadow: none; }
      .login form input:focus,
      .login form select:focus,
      .login form textarea:focus {
        border: 1px solid #2cadfe;
        border-radius: 1px solid #2cadfe;
        box-shadow: none; }
  .login .button.button-large {
    padding: 0 0 40px;
    width: 100%;
    height: 30px;
    line-height: 40px; }
  .login .button-primary {
    transition: ease .5s;
    margin-top: 50px;
    border: 0;
    box-shadow: none;
    background: #ff3632;
    padding: 15px;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: none;
    letter-spacing: 2px;
    color: #fff;
    font-weight: 500; }
    .login .button-primary:hover, .login .button-primary:active, .login .button-primary:focus {
      transition: ease .5s;
      border: 0;
      box-shadow: none;
      background-color: #ff1d19;
      text-decoration: none;
      color: #ff3632; }
  .login .message {
    border-left: 4px solid #ff3632; }
